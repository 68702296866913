<template>
    <v-data-table v-bind="{ headers, items }" dense hide-default-footer disable-sort disable-filtering disable-pagination @click:row="(any, { item }) => showBoard(item)" no-data-text="등록된 공지사항이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.subject`]="{ item }">
            <div data-idx="5" class="text-truncate">{{ item.subject }}</div>
        </template>

        <template #footer>
            <div v-show="false" class="btn-wrap">
                <v-row class="row--xs">
                    <v-col cols="auto">
                        <v-btn outlined color="grey darken-4" @click="$emit('loadmore')">리스트</v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn color="grey darken-4" @click="$router.push('?mode=input')">글쓰기</v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- <v-btn @click="$router.push(`${$route.path}/write`)" large color="primary">Write</v-btn> -->

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="count" :total-visible="11" @change="changePage" />
            </div>
            <!-- <pagination-component v-bind="{ count }" :value="page" @change="changePage" /> -->
        </template>
    </v-data-table>
</template>

<script>
import PaginationComponent from "@/components/client/pagination-component.vue";
const categories = [];
const headers = [
    { align: "center", text: "제목", value: "subject" },
    { width: "16%", align: "center", text: "작성자", value: "writer", formatter: (value) => value?.name || "-" },
    { width: "16%", align: "center", text: "작성일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "16%", align: "center", text: "조회수", value: "viewCount" },
];

export default {
    components: {
        PaginationComponent,
    },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    data: () => ({
        headers,
        categories: [],
    }),
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        items() {
            return this.boards.map((board, index) => ({ ...board, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
};
</script>

<style scoped></style>
